.bwn_about_us_counts_list_box_inner_box {
    background-color: #fff;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    padding: 60px 15px;
    text-align: center;
}

.bwn_about_countup {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 40px;
}

.project_management_team_section .bwn_our_leadership_section {
    background: none;
    padding: 0px 0 0px 0;
}

.project_management_team_section {
    padding: 10px 0px 80px 0px;
    background: #f5f7fc;
}

.p_team_text_content h2 {
    color: white;
    font-size: 17px;
    margin-bottom: 5px;
}

.p_team_text_content p {
    color: white;
    font-size: 12.5px;
}

.p_team_text_content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}

.bw_teams img {
    width: 100%;
    height: auto;
}

.p_team_main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px 15px;
}

.project_management_team_box {
    position: relative;
}

.project_management_team_box img {
	max-width: 100%;
	width: 100%;
	height: auto;
}

.p_team_main.marketing_team_main {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 15px 15px;
	justify-content: center;
}

.p_team_main.marketing_team_main:nth-child(n+6) {
    grid-column: span 3;
}

.p_team_main.marketing_team_main .project_management_team_box img {
    width: 100%;
    height: auto;
}

.bwn_about_us_counts_list_box ul {
    margin: 50px 0;
}

.bwn_about_us_counts_list_box ul li {
    width: 32%;
    display: inline-block;
    margin-right: 20px;
}

.bwn_about_us_counts_list_box ul li:last-child {
    margin-right: 0;
}

.bwn_about_us_counts_list_box_inner_box p {
    font-weight: 600;
    font-size: 20px;
    color: #3D63C3;
    line-height: 45px;
    margin-bottom: 0;
}

.bwn_about_us_associates_box ul li {
    width: 16.5%;
    display: inline-block;
}

.bwn_about_us_associates_box h4 {
    font-weight: 700;
    font-size: 45px;
    line-height: 65px;
    color: #3D63C3;
    text-align: center;
    margin-bottom: 50px;
}

.bwn_about_us_associates_box_img img {
    width: 135px;
    height: auto;
    cursor: pointer;
}

.bwn_about_us_associates_box_img {
    text-align: center;
}

.bwn_new_about_us_text_box {
    background-image: url('../images/about-us/about-bg2.webp');
    width: 100%;
    background-repeat: no-repeat;
    /* display: flex; */
    justify-content: center;
    /* align-items: center;
    vertical-align: middle; */
    /* padding: 90px 0 60px; */
    background-size:cover;
    background-position: 100% 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    min-height: 500px;
}

.bwn_bg_image_of_text h4 {
    font-weight: 600;
    font-size: 50px;
    line-height: 45px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
}

.bwn_bg_image_of_text p {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    text-align: center;
}

.bwn_our_leadership_section_iiner_box {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding: 20px 20px 20px 20px;
    text-align: center;
}

.bwn_our_leadership_section_iiner_box h5 {
    font-weight: 600;
    font-size: 27px;
    line-height: 60px;
    color: #3D63C3;
    text-align: center;
    margin-bottom: 0;
}

.bwn_our_leadership_section_iiner_box p {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
}

.bwn_our_leadership_section_iiner_box img {
    margin-bottom: 10px;
}

.bwn_member_button {
    margin-right: 10px;
}

.bwn_member_button img {
    width: 36px;
    height: 36px;
    cursor: pointer;
}

.bwn_member_button:last-child {
    margin-right: 0 !important;
}

.btn_outer_box {
    justify-content: center;
}

.bwn_member_button svg {
    font-size: 18px !important;
    -webkit-font-size: 18px !important;
    height: 18px;
}

.bwn_our_leadership_section h3 {
    font-weight: 600;
    font-size: 45px;
    line-height: 50px;
    color: #3D63C3;
    text-align: center;
    margin: 0 auto 40px;
}

.bwn_our_leadership_section ul li {
    width: 31.33%;
    display: inline-block;
    margin-right: 20px;
}

.bwn_our_leadership_section {
    padding: 60px 0;
    background-color: #F5F7FC;
}

.bwn_our_leadership_section ul li:last-child {
    margin-right: 0;
}

.btn_inta {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.btn_whtatsapp {
    background-color: #25d366;
}

.btn_facbook {
    background-color: #1877F2;
}

.bwn_about_us_video {
    margin-top: 100px;
}

.bwn_member_button:last-child {
    margin-right: 0 !important;
}

/* ABout Ashish Jangir Page style */

.bw_ash_profile_box {
    box-shadow: 0 15px 38px 0 rgba(0, 0, 0, 0.1);
    margin-top: 150px;
    margin-bottom: 0;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 30px;
}

.bw_img_assh_profile img {
    /* width: 50%; */
    height: auto;
    margin: 0 auto;
    margin-bottom: 25px !important;
}

.bw_cursor_pointer {
    cursor: pointer;
}

.bw_ash_text_box p {
    color: #616161;
    font-size: 16px;
    line-height: 20px;
}

.bw_ash_text_box p strong {
    color: #212121;
    line-height: 20px;
    font-size: 15px;
    margin-right: 7px;
}

.bw_ash_text_box h2 {
    font-size: 28px;
    color: #212121;
    line-height: 25px;
    margin-bottom: 12px;
    font-weight: 600;
}

.bw_ash_text_box h5 {
    font-size: 16px;
    line-height: 25px;
    color: #3D63C3;
    font-weight: 600;
    margin-bottom: 15px;
}

.bw_ashish_social_icons_box_outer {
    display: flex;
    justify-content: start;
    vertical-align: middle;
    align-items: center;
    padding-top: 15px;
}

.bw_ash_text_box svg {
    font-size: 16px;
    line-height: 35px;
    color: #c3c3c3;
    margin-right: 20px;
}

.bw_ash_text_box svg:hover {
    color: #212121;
    transition: all .4s ease-in-out;
}

.bw_Professional_Experience h6 {
    color: #212121;
    line-height: 20px;
    font-size: 20px;
    margin-right: 9px;
    margin-bottom: 15px;
}

.bw_Professional_Experience p {
    font-size: 14px;
    margin-bottom: 13px;
    line-height: 21px;
    color: #616161;
    margin-bottom: 5px;
}

/* .p_team_main.marketing_team_main.marketing_team {
	display: flex !important;
    justify-content: center !important;
} */
.bw_ash_text_box p a {
    color: #616161;
    font-size: 14px;
}

.bw_ashish_social img {
    width: 35px;
    height: auto;
}

.bw_ashish_social {
    margin-bottom: 15px;
}

.bw_ashish_social a {
    margin-right: 12px;
}

.bw_ashish_social a:last-child {
    margin-right: 0;
}

.bw_ash_pera_read_more {
    color: #000;
    text-decoration: underline;
}

.bw_calendly_margin_top {
    /* margin-top: 120px; */
}

.bw_ash_text_box {
    text-align: center;
}

.bw_img_assh_profile {
    text-align: center;
}

/* About Sami page Style */
.bw_calendly_margin_top.bw_meet_sami {
    margin: 150px auto 0;
}

.bw_img_assh_profile img {
    /* width: 60%; */
}

/* \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */
/* Our Dedicated Team  section   */
.bwn_our_dedicated_team {
    background-color: #3D63C3;
}

.bwn_title_team_dede {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 30px;
}

.bwn_tabs_our_dedecated {
    padding-bottom: 60px;
}

.bwn_title_team_dede h3 {
    font-weight: 600;
    font-size: 45px;
    line-height: 45px;
    color: #fff;
    text-align: center;
    margin: 0 auto 40px;
}

.bwn_title_team_dede p {
    color: #FFF;
    font-size: 22px;
    font-weight: 400;
}

.bw_iner_teams .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: transparent;
    opacity: inherit;
}

.tab_head h3 {
    color: #FFF;
    font-size: 35px;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 0;
}

.tab_head h3::before {
    position: absolute;
    top: 15px;
    left: -30px;
    content: '';
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 100%;
}

.tab_head .nav-pills .nav-link {
    color: #fff !important;
}

.bw_iner_teams .nav-item {
    padding-bottom: 20px;
}

.bw_iner_teams .nav-pills .nav-link {
    cursor: pointer;
    opacity: 0.5;
}

.tab_head svg {
    margin-right: 13px;
}

/* \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */
/* \\\\\\\\\\\\\\\\\\\Retreat Gathering \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/
section.bwn_annual_event {
    padding-top: 100px;
}

.bwn_title_retreat {
    text-align: center;
    color: #3D63C3;
}

.bwn_title_retreat h5 {
    font-size: 30px;
    font-weight: 600;
}

.bwn_title_retreat h3 {
    font-size: 50px;
    font-weight: 600;
}

.tabs_retret .twotabs {
    justify-content: center;
    margin: auto;
}

.tabs_retret .twotabs {
    border-radius: 6px;
    border: 1px solid #a6a6a6;
    padding: 3px 0px;
    position: relative;
    /* width: 361px; */
    width: 366px;
    height: 68px;
    /* border: 2px solid #2C52B0;
    opacity: 0.1;
    background: #FFF; */
}

.tabs_retret .nav-pills .nav-link {
    border-radius: 6px;
    width: 178px;
    height: 58px;
    text-align: center;
    padding-top: 10px;
    font-size: 24px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: #2C52B0;
}

.tabs_retret .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #3d63c3;
}

.tabs_retret {
    padding-top: 30px;
}

.bwn_retreat_images img {
    object-fit: cover;
    width: auto;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    /* grid-gap: 1px; */
    /* background-color: #2196F3; */
    /* padding: 10px; */
}

.grid-container>div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    /* padding: 20px 0; */
    /* font-size: 30px; */
}

.item6 {
    grid-column: 2 / span 2;
    grid-row: 2 /span 2;
}













/* \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

/* \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */
/********************** Responsive style********************** */
@media(max-width:767px) {
    .bwn_desktop_view_of_associates_logo {
        display: none;
    }

    .bwn_about_us_banner {
        display: none;
    }

    .bw_Professional_Experience.desktop_content {
        display: none;
    }
    .bw_calendly_margin_top .calendly-inline-widget {
        height: 900px !important;
    }
}

@media(min-width:768px) {
    .bwn_Mobile_view_of_associates_logo {
        display: none;
    }

    .bwn_about_us_video {
        display: none;
    }

    .bw_Professional_Experience.bw_ashi_mobile_content_read_more {
        display: none;
    }
}

@media(min-width:320px) and (max-width:767px) {
    .bw_custome_container_ash_about {
        padding: 100px 0 30px !important;
    }
    .bw_about_aash::before {
        top: 75px !important;
    }
    .bw_about_aash::before {
        width: 140px !important;
    }
    .bw_about_aash::after {
        width: 150px !important;
    }
    .p_team_main.marketing_team_main .project_management_team_box img {
        width: 100%;
        height: 220px;
        object-fit: cover;
    }

    .tab_head h3::before {
        top: 7px;
        left: -18px;
        width: 10px;
        height: 10px;
    }

    .bwn_title_team_dede h3 {
        font-size: 30px;
        line-height: 35px;
        margin: 0 auto 0px;
    }

    .bw_calendly_margin_top.bw_meet_sami {
        margin: 0px auto 0;
    }

    .bw_ashi_img_pading_mobile_view {
        /* padding: 0 5px 0 15px !important; */
        padding: 0 !important;
        left: 20px;
    }

    .bw_Professional_Experience.bw_ashi_mobile_content_read_more {
        text-align: center;
    }

    .bw_ashish_social img {
        width: 23px;
    }

    .bw_calendly_margin_top {
        margin-top: 0;
        margin-bottom: 20px;
    }

    .bw_ashish_social {
        text-align: left;
    }

    .bw_ashish_social a {
        margin-right: 8px;
    }

    .bw_img_assh_profile {
        text-align: center;
    }

    .bw_ash_text_box p a {
        font-size: 14px;
    }

    .p_team_main.marketing_team_main.marketing_team {
        display: grid !important;
    }



    .bw_ash_profile_box {
        margin-bottom: 15px;
        border-radius: 10px;
        padding: 25px 15px;
        margin-top: 120px;
    }

    .bw_ash_text_box h2 {
        font-size: 23px;
        line-height: 27px;
        margin-bottom: 0;
        margin-top: 0;
        text-align: left;
    }

    .bw_ash_text_box h2 {
        font-size: 23px;
        line-height: 21px;
        margin-bottom: 5px;
        margin-top: 0;
        text-align: left;
    }

    .bw_ash_text_box h5 {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 5px;
        text-align: left;
    }

    .bw_ash_text_box p strong {
        line-height: 20px;
        font-size: 15px;
        margin-right: 5px;
    }

    .bw_ash_text_box p {
        font-size: 15px;
        line-height: 20px;
    }

    .bw_Professional_Experience h2 {
        font-size: 23px;
        line-height: 25px;
        margin-bottom: 20px;
    }

    .bw_Professional_Experience p {
        font-size: 14px;
        margin-bottom: 5px;
        line-height: 22px;
        text-align: left;
    }

    .bw_ash_pera_read_more {
        color: #000;
        text-decoration: underline;
        text-align: left;
    }

    .bwn_member_button svg {
        font-size: 18px !important;
        -webkit-font-size: 18px !important;
        height: 18px !important;
    }

    .p_team_main.marketing_team_main {
        gap: 12px 12px;
    }

    .p_team_text_content h2 {
        font-size: 16px;
        margin-bottom: 2px;
    }

    .p_team_main {
        grid-template-columns: repeat(2, 1fr);
        gap: 12px 12px;
    }

    .p_team_text_content p {
        font-size: 11px;
        margin-bottom: 5px;
    }

    .project_management_team_section {
        padding: 10px 0px 40px 0px;
        background: #f5f7fc;
    }

    .p_team_main.marketing_team_main {
        grid-template-columns: repeat(2, 1fr);
    }

    .bwn_new_about_us_text_box {
        padding: 40px 0 40px;
        background-size: 100% 100%;
        margin-top: -6px;
    }

    .bwn_member_button {
        width: 35px;
        height: 35px;
    }

    .bwn_bg_image_of_text h4 {
        font-size: 35px;
        line-height: 35px;
    }

    .bwn_our_leadership_section {
        padding: 40px 0;
    }

    .bwn_our_leadership_section h3 {
        font-size: 30px;
        line-height: 30px;
        margin: 0 auto 30px;
    }

    .bwn_our_leadership_section ul li {
        width: 100%;
        padding: 7.5px 15px;
    }

    .bwn_our_leadership_section_iiner_box h5 {
        font-size: 25px;
        line-height: 45px;
    }

    .bwn_our_leadership_section_iiner_box p {
        font-size: 16px;
        line-height: 20px;
    }

    .bwn_bg_image_of_text p {
        font-size: 16px;
        line-height: 25px;
        width: 90%;
        margin: 0 auto;
    }

    /* .bwn_about_us_counts_list_box ul li {
	width: 100%;
	display: block;
	padding: 7.5px 15px;
} */
    .bwn_about_us_counts_list_box ul li {
        width: 50%;
        display: inline-block;
        padding: 7.5px 0px;
        margin: 0;
        margin-right: 0 !important;
    }

    .bwn_about_us_counts_list_box ul li:first-child {
        width: 100%;
    }

    .bwn_about_us_counts_list_box ul li:last-child:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto 0;
        height: 80%;
        background: #6f7da0;
        z-index: 99;
        width: 1px;
    }

    .bwn_about_us_counts_list_box ul li:last-child {
        position: relative;
    }

    .bwn_home_about_us_group_image_box img {
        margin-top: 30px;
    }

    .bwn_about_us_counts_list_box ul {
        margin: 20px 0;
    }

    .bwn_about_us_counts_list_box_inner_box {
        padding: 30px 10px 25px 10px;
    }

    .bwn_about_us_counts_list_box_inner_box p {
        font-size: 16px;
        line-height: 28px;
        font-weight: 500;
    }

    .bwn_about_us_counts_list_box_inner_box {
        padding: 40px 15px;
    }

    .bwn_about_countup {
        font-size: 30px;
        line-height: 30px;
    }

    .bwn_about_us_associates_box h4 {
        font-size: 40px;
        line-height: 35px;
        margin-bottom: 35px;
    }

    .bwn_about_us_counts_list_box ul {
        margin: 35px 0;
    }

    .bwn_about_us_associates_box_img img {
        width: 100px;
    }

    /* our dedcated team */
    .bw_teams img {
        width: 100%;
        margin-top: 20px;
    }

    .bwn_title_team_dede h3 {
        font-size: 30px;
    }

    .bwn_title_team_dede p {
        font-size: 16px;
    }

    .bw_iner_teams .nav-item {
        padding-bottom: 5px;
    }

    .tab_head h3 {
        font-size: 18px;
        margin-bottom: 0;
    }

    .tab_head svg {
        width: 10px;
        width: 10px;
    }

    .bwn_title_team_dede {
        padding-top: 50px;
        padding-bottom: 30px;
    }

    .bwn_tabs_our_dedecated {
        padding-bottom: 30px;
    }
}

@media(min-width:361px) and (max-width:767px) {
    .bw_ashish_social img {
        width: 28px;
    }



    .bw_ash_text_box h2 {
        font-size: 28px;
        line-height: 25px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .bw_about_aash::before {
        top: 75px !important;
    }
    .bw_about_aash::before {
        width: 200px !important;
    }
    .bw_about_aash::after {
        width: 220px !important;
    }
    .bw_MeetOurCto_section .bw_aj_content {
        font-size: 15px;
        line-height: 30px;
        margin: 0 60px !important;
    }
    .tab_head h3::before {
        top: 6px;
        left: -20px;
        content: '';
        width: 8px;
        height: 8px;
    }

    .tab_head h3 {
        font-size: 20px;
    }

    .bw_iner_teams .nav-item {
        padding-bottom: 0px;
    }

    .bw_img_assh_profile img {
        width: 30%;
    }

    .bw_img_assh_profile img {
        width: 30%;
        margin-bottom: 15px !important;
    }

    .bw_calendly_margin_top.bw_meet_sami {
        margin: 30px auto 0;
    }

    .bw_teams img {
        width: 100%;
    }
}

@media(min-width:992px) and (max-width:1024px) {
    .bwn_tabs_our_dedecated {
        padding-bottom: 50px;
    }

    .tab_head h3::before {
        top: 7px;
        left: -20px;
        width: 10px;
        height: 10px;
    }

    .bw_iner_teams .nav-item {
        padding-bottom: 10px;
    }

    .bw_ash_text_box h2 {
        font-size: 20px !important;
        line-height: 10px !important;
    }

    .bw_ash_text_box h5 {
        font-size: 14px !important;
        line-height: 26px !important;
        margin-bottom: 10px !important;
    }

    .bw_ashish_social img {
        width: 30px !important;
    }

    .tab_head h3 {
        font-size: 20px;
    }

    .bw_teams img {
        width: 100%;
    }
}

@media(min-width:768px) and (max-width:1024px) {
    .bw_custome_container_ash_about {
        padding: 100px 0 30px !important;
    }
    .ashi_visit_title {
        width: 75%;
    }
    .bw_custome_container_ash_about .bw_img_assh_profile img {
        margin-bottom: 30px !important;
    }
    .bwn_title_team_dede h3 {
        font-size: 38px;
        line-height: 35px;
        margin: 0 auto 40px;
    }

    .bwn_title_team_dede {
        padding-top: 30px;
        padding-bottom: 0px;
    }

    .bwn_tabs_our_dedecated {
        padding-bottom: 40px;
    }

    .bwn_about_us_counts_list_box ul li {
        width: 31.3%;
    }


    .p_team_main {
        grid-template-columns: repeat(3, 1fr);
    }

    .p_team_main.marketing_team_main {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px 12px;
    }

    .project_management_team_box img {
        max-width: 100%;
        width: 100%;
    }

    .bwn_about_us_counts_list_box_inner_box p {
        font-size: 18px;
    }

    .bwn_home_about_us_group_image_box img {
        margin-top: 30px;
    }

    .bwn_about_countup {
        font-size: 40px;
    }

    .bwn_about_us_associates_box_img img {
        width: 100px;
    }

    .bwn_about_us_associates_box h4 {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 30px;
    }

    .bwn_our_leadership_section_iiner_box h5 {
        font-size: 22px;
        line-height: 45px;
    }

    .bwn_our_leadership_section_iiner_box p {
        font-size: 16px;
    }

    .bwn_member_button {
        width: 35px;
        height: 35px;
    }

    .bwn_our_leadership_section {
        padding: 40px 0;
    }

    .bwn_our_leadership_section h3 {
        font-size: 38px;
    }

    .bwn_bg_image_of_text h4 {
        font-size: 38px;
    }

    .bwn_new_about_us_text_box {
        padding: 40px 0 40px;
    }

    .bwn_about_us_banner img {
        margin-top: 60px;
    }

}

@media(min-width:1240px) and (max-width:1650px) {
    .bwn_new_about_us_text_box {
        /* padding: 50px 0 40px; */
    }

    .bwn_bg_image_of_text h4 {
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 10px;
    }

    .bwn_bg_image_of_text p {
        font-size: 14px;
        line-height: 25px;
        width: 80%;
        margin: 0 auto;
    }

    .bwn_our_leadership_section h3 {
        font-size: 35px;
        line-height: 40px;
        margin: 0 auto 30px;
    }

    .bwn_our_leadership_section {
        padding: 35px 0;
    }

    .bwn_our_leadership_section_iiner_box h5 {
        font-size: 25px;
        line-height: 45px;
    }

    .bwn_our_leadership_section_iiner_box p {
        font-size: 16px;
        line-height: 15px;
    }

    .bwn_about_us_counts_list_box_inner_box {
        padding: 40px 15px;
    }

    .bwn_about_countup {
        font-size: 35px;
        line-height: 30px;
    }

    .bwn_about_us_counts_list_box_inner_box p {
        font-size: 18px;
        line-height: 40px;
    }

    .bwn_about_us_associates_box h4 {
        font-size: 40px;
        line-height: 35px;
        margin-bottom: 35px;
    }

    .bwn_about_us_counts_list_box ul {
        margin: 35px 0;
    }

    .bwn_about_us_associates_box_img img {
        width: 100px;
    }
}

@media(min-width:768px) and (max-width:1024px) {

    .bw_ash_profile_box {
        margin-bottom: 0;
        padding: 30px 30px 30px 33px;
    }

    .bw_ash_text_box h2 {
        font-size: 30px;
        line-height: 25px;
    }

    .bw_ash_text_box h5 {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 10px;
    }

    .bw_ash_text_box p strong {
        line-height: 20px;
        font-size: 15px;
        margin-right: 9px;
    }

    .bw_ash_text_box p {
        font-size: 15px;
        line-height: 20px;
    }

    .bw_ash_text_box svg {
        font-size: 14px;
        line-height: 30px;
        margin-right: 15px;
    }

    .bw_ashish_social_icons_box_outer {
        padding-top: 7px;
    }

    .bw_Professional_Experience h2 {
        font-size: 25px;
        line-height: 40px;
        margin-bottom: 15px;
    }

    .bw_Professional_Experience p {
        font-size: 14px;
        margin-bottom: 12px;
        line-height: 22px;
    }

    .bw_about_aash ._cUP1np9gMvFQrcPftuf._Y8HCTxgNkwxXcG_DCXx {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}
@media(min-width:992px) and (max-width:1024px){
    .bw_about_aash::before {
        width: 200px;
        height: auto;
        left: 0;
        top: 70px !important;
    }
    .bw_about_aash::before {
        width: 180px !important;
    }
}
@media(min-width:1200px) and (max-width:1440px) {
    .bw_custome_container_ash_about {
        padding: 100px 0 30px !important;
    }
    .ashi_visit_title {
        width: 70%;
    }
    .bw_about_aash::before {
        width: 250px !important;
        height: 250px;
        left: 0;
        top: 52px !important;
    }
    .bw_about_aash::after {
        width: 350px !important;
        height: 250px !important;
    }
    .bw_MeetOurCto_section .bw_new_ash_About_row .bw_new_ash_About_col_3 {
        position: unset;
    }
    .bw_MeetOurCto_section .bw_aj_content {
        font-size: 16px;
        margin: 0px 80px 0;
        line-height: 26px;
    }

    /* .bw_new_ash_About_row.row {
        margin: 80px 0 120px;
    } */

    .bwn_tabs_our_dedecated {
        padding-bottom: 50px;
    }

    .tab_head h3::before {
        top: 15px;
        left: -30px;
        width: 10px;
        height: 10px;
    }

    .bw_iner_teams .nav-item {
        padding-bottom: 15px;
    }

    .tab_head h3 {
        font-size: 30px;
    }

    .bwn_title_team_dede h3 {
        font-size: 35px;
        line-height: 35px;
        margin: 0 auto 20px;
    }

    .bw_calendly_margin_top.bw_meet_sami {
        margin: 110px auto 0;
    }

    

    .bw_ash_pera_read_more {
        font-size: 12px;
        line-height: 6px;
    }

    .bw_ashish_social {
        margin-bottom: 10px;
    }

    .bw_ashish_social a:first-child {
        margin-right: 12px;
    }

    .bw_ashish_social img {
        width: 25px;
    }

    .bw_ash_text_box p a {
        font-size: 12px;
    }

    .bw_all_banner.bw_banner_pricing.bw_banner_ashi {
        padding: 25px 0 55px;
    }

    .bw_ash_profile_box {
        padding: 20px;
        margin-top: 0;
    }

    .bw_ash_text_box h2 {
        font-size: 17px;
        line-height: 15px;
    }

    .bw_ash_text_box h5 {
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 10px;
    }

    .bw_ash_text_box p strong {
        line-height: 20px;
        font-size: 12px;
        margin-right: 5px;
    }

    .bw_ash_text_box p {
        font-size: 11.5px;
        line-height: 15px;
        margin-bottom: 8px;
    }

    .bw_ash_text_box svg {
        font-size: 14px;
        line-height: 30px;
        margin-right: 15px;
    }

    .bw_Professional_Experience h6 {
        line-height: 18px;
        font-size: 16px;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .bw_ashish_social_icons_box_outer {
        padding-top: 7px;
    }

    .bw_Professional_Experience h2 {
        font-size: 25px;
        line-height: 40px;
        margin-bottom: 15px;
    }

    .bw_Professional_Experience p {
        font-size: 12.5px;
        margin-bottom: 5px;
        line-height: 18px;
    }

    .bw_about_aash ._cUP1np9gMvFQrcPftuf._Y8HCTxgNkwxXcG_DCXx {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .bw_img_assh_profile img {
        width: 40%;
        margin-bottom: 15px !important;
    }

    .p_team_main.marketing_team_main .project_management_team_box img {
        width: 100%;
    }
}

@media(min-width:1441px) and (max-width:1600px) {
    .bw_ash_profile_box {
        margin-top: 100px;
    }

    .bw_calendly_margin_top {
        margin-top: 35px;
    }
}

@media(min-width:1150px) and (max-width:1350px) {
    .bw_Professional_Experience p {
        font-size: 10.5px;
        margin-bottom: 5px;
        line-height: 16px;
    }

    .bw_ashish_social img {
        width: 25px;
    }
}

.bw_aj_content {
	font-family: 'Poppins';
	font-size: 16px;
	font-weight: 400;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: left;
	color: #616161;
	padding: 20px 150px 0;
}

.bw_aj_content b {
    font-weight: 600;
}

.bw_ash_profile_box_new {
    margin-top: 92px;
    position: absolute;
    right: -299px;
    z-index: 9;
    top: 0;
}

.bw_custome_container_ash_about {
    max-width: 1400px !important;
    padding: 155px 0 45px;
    margin: auto;
}

.bw_about_aash {
    /* background-image:  url('../images/about-us/not-bridge.png'); */
    /* background-position: left bottom; */
    /* background-repeat: no-repeat, no-repeat; */
    position: relative;
    width: 100%;
}
.bw_about_aash:before{
    position: absolute;
    content: '';
    background-image: url('../../Assets/images/about-us/before-img.png');
    background-repeat: no-repeat;
    width: 300px;
    height: 250px;
    left: 0;
  top: 100px;
  background-size: contain;
  display: none;
}
.bw_about_aash:after{
    position: absolute;
    content: '';
    background-image: url('../../Assets/images/about-us/after-img.png');
    background-repeat: no-repeat;
    width: 350px;
    height: 250px;
    right: 0;
    bottom: 0;
    background-position: right bottom;
    background-size: contain;
    display: none;
}

.bw_custome_container_ash_about .bw_img_assh_profile img {
    width: 50%;
    border: 6px solid #E8E8E8;
    padding: 0;
}

.bw_new_ash_About_row.row {
    margin-top: -50px;
}

.client-country {
    background: #F5F7FC;
    padding: 50px;
}

.client-country h3 {
    font-family: Poppins;
    font-size: 50px;
    font-weight: 500;
    line-height: 75px;
    text-align: center;
    color: #3D63C3;
    margin-bottom: 15px;
}

@media (min-width:1280px) and (max-width:1439px) {
    .bw_custome_container_ash_about {
        padding: 90px 0 50px;
    }

    .bw_ash_profile_box_new {
        margin-top: 140px;
        position: relative;
        right: -62px;
    }
    .bw_custome_container_header1.bw_custome_container_ash_about.container > img {
        width: 60%;
    }

    .bw_new_ash_About_row .bw_new_ash_About_col_3 {
        position: absolute;
    }
    .bw_MeetOurCto_section .bw_new_ash_About_row .bw_new_ash_About_col_3 {
        position: unset;
    }
    .bw_MeetOurCto_section .bw_aj_content {
        font-size: 16px;
        padding: 0px 80px 0;
        line-height: 26px;
    }
    .bw_new_ash_About_row .bw_new_ash_About_col_9 {
        max-width: 100%;
        flex: 0 0 100%;
        margin-left: 130px;
    }
    .bw_custome_container_ash_about .bw_img_assh_profile img {
        width: 100%;
    }
    .bw_calendly_margin_top iframe {
        height: 660px !important;
    }
    .bw_calendly_margin_top .calendly-inline-widget {
        height: 645px !important;
    }
}
@media (min-width:1280px) and (max-width:1300px) {
    .bw_ash_profile_box_new {
        right: -65px;
    }
}
@media (min-width:1301px) and (max-width:1350px) {
    .bw_ash_profile_box_new {
        right: -82px;
    }
}
@media (min-width:1351px) and (max-width:1400px) {
    .bw_ash_profile_box_new {
        right: -92px;
    }
}
@media (min-width:1401px) and (max-width:1430px) {
    .bw_ash_profile_box_new {
        right: -86px;
    }
}
@media (min-width:1440px) and (max-width:1450px) {
    .bw_ash_profile_box_new {
        right: -300px;
        margin-top: 130px;
    }
}
@media (min-width:1451px) and (max-width:1600px) {
    .bw_ash_profile_box_new {
        margin-top: 130px;
    }
    .bw_about_aash::before {
        width: 250px;
        height: 240px;
        top: 70px;
    }
    .bw_about_aash::after {
        width: 300px;
        height: 250px;
    }
}

@media(max-width:991px) {
    .bw_custome_container_ash_about .bw_img_assh_profile img {
        width: 100%;
        border: 3px solid #E8E8E8;
        margin-bottom: 0 !important;
    }

    .bw_ash_profile_box_new {
        margin: 75px 10px 30px;
        position: static;
    }

    .bw_ash_profile_box_new>.row {
        align-items: center;
    }

    .bw_aj_content {
        font-size: 15px;
        line-height: 24px;
        padding: 15px;
    }

    .bw_about_aash {
        background-size: 160px;
    }

    .bw_ash_text_box {
        padding-left: 20px;
    }

    .client-country h3 {
        font-size: 23px;
        line-height: 35px;

    }

    .client-country {
        padding: 28px 20px;
    }
}

@media (min-width:769px) and (max-width:991px) {
    .bw_ash_profile_box_new>.row {
        /* flex-wrap: nowrap; */
        justify-content: center;
    }

    .bw_ashi_img_pading_mobile_view {
        max-width: 30% !important;
    }

    .bw_ash_profile_box1.bw_ash_profile_box_new>.row>.col-lg-12.col-md-12.col-sm-8.col-8 {
        max-width: 40% !important;
    }

    .bw_ash_text_box {
        text-align: left;
    }

    .bw_aj_content {
        padding-top: 30px !important;
    }
}
@media (min-width:992px) and (max-width:1024px) {
.bw_aj_content {
	font-family: 'Poppins';
	font-size: 16px;
	font-weight: 400;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: left;
	color: #616161;
	padding: 30px 55px 0;
}
.p_team_main.marketing_team_main .project_management_team_box img {
	width: 100%;
	height: 400px !important;
	object-fit: cover !important;
}
.project_management_team_section {
	padding: 10px 0px 30px 0px;
}
}
@media (min-width:991px) and (max-width:1279px) {
    .bw_calendly_margin_top {
        margin-top: 58px;
    }

    .bw_custome_container_ash_about .bw_img_assh_profile img {
        width: 100%;
    }

    .bw_ash_profile_box_new {
        margin-top: 92px;
        position: relative;
        right: -58px;
        z-index: 9;
        top: 100px;
    }
}